<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg10 xl7>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-note-multiple-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="sincronizar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- :error-messages="nomeErrors" 
                              required
                  @input="$v.varNotaLocal.nome_nota.$touch()"
                  @blur="$v.varNotaLocal.nome_nota.$touch()"-->

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="varNotaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="varNotaLocal.titulo"
                    label="Título"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="varNotaLocal.criacao"
                    label="Criacao"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="varNotaLocal.criado"
                    label="Criado"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <quill-editor
                    class="mt-4"
                    ref="editor"
                    v-model="varNotaLocal.nota"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("utilNotas");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varNotaLocal: {
  //     nome_nota: { required },
  //     email: { required, email },
  //     nota: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "UtilNotasForm",
    tituloForm: "",
    varNotaLocal: {},
  }),

  computed: {
    ...mapState(["staNotaSelect", "listaUsuarios"]),
  },

  watch: {
    // notaSelect(notaNovo) {
    //   this.sincronizar(notaNovo);
    // },
  },

  created() {
    this.sincronizar();
    if (this.staNotaSelect) {
      this.tituloForm = "Editar Nota";
    } else {
      this.tituloForm = "Nova Nota";
    }
    // this.varNotaLocal.criado = this.mixDataDBT(this.varNotaLocal.criado);
  },
  methods: {
    ...mapActions([
      "actSelecionarNota",
      "actResetarNota",
      "actCriarNota",
      "actEditarNota",
      "actResetarErro",
    ]),

    obterUsuario(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].usuario;
      } else {
        const ret = "";
        return ret;
      }
    },

    selecionarNotaEdicao(nota) {
      this.actSelecionarNota({ nota });
    },

    async salvarNota() {
      switch (this.staNotaSelect) {
        case undefined:
          this.varNotaLocal.criacao = this.$store.state.login.usuario.usuario;
          this.varNotaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varNotaLocal.criado = undefined;
          this.varNotaLocal.modificado = undefined;
          await this.actCriarNota({
            nota: this.varNotaLocal,
            licenca: this.$store.state.login.licenca,
          });
          await this.sincronizar();
          if (this.$store.state.utilNotas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.utilNotas.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Nota";
            this.$root.snackbar.show({
              type: "success",
              message: "Nota Criada!",
            });
          }
          break;
        default:
          this.varNotaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          // this.varNotaLocal.criacao = undefined;
          // this.varNotaLocal.criado = undefined;
          // this.varNotaLocal.modificado = undefined;
          await this.actEditarNota({
            nota: this.varNotaLocal,
            licenca: this.$store.state.login.licenca,
          });
          await this.sincronizar();
          if (this.$store.state.utilNotas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.utilNotas.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Nota Salva!",
            });
          }
      }
      //this.resetar();
    },

    async sincronizar() {
      this.varNotaLocal = await Object.assign(
        {},
        this.staNotaSelect || {
          id: null,
        }
      );
      this.varNotaLocal.criado = this.mixDataDBT(this.varNotaLocal.criado);
    },
    submit() {
      // this.$v.$touch();
      this.salvarNota();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varNotaLocal.nota = this.staNotaSelect.nota;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
